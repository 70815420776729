export const DESKTOP_FONT_SIZE = {
	fontSize: 16,
	h1: 48,
	h2: 40,
	h3: 33,
	h4: 28,
	h5: 23,
	h6: 19,
	subtitle1: 16,
	subtitle2: 14,
	body1: 14,
	body2: 16,
	button: 16,
	caption: 16,
}

export const DESKTOP_FONT_SIZE_OLD = {
	fontSize: 16,
	h1: 54,
	h2: 36,
	h3: 24,
	h4: 20,
	h5: 16,
	h6: 16,
	subtitle1: 16,
	subtitle2: 14,
	body1: 14,
	body2: 16,
	button: 16,
	caption: 16,
}

export const LINE_HEIGHT = {
	lineHeight: 1.5,
	h1: 1.2,
	h2: 1.2,
	h3: 1.5,
	h4: 1.5,
	h5: 1.5,
	h6: 1.5,
	subtitle1: 1.5,
	subtitle2: 1.5,
	body1: 1.5,
	body2: 1.5,
	button: 1.5,
	caption: 1.5,
}

export const MOBILE_FONT_SIZE = {
	fontSize: 16,
	h1: 40,
	h2: 32,
	h3: 22,
	h4: 18,
	h5: 16,
	h6: 14,
	subtitle1: 16,
	subtitle2: 14,
	body1: 14,
	body2: 16,
	button: 16,
	caption: 16,
}

export const PDF_FONT_SIZE = {
	fontSize: 16,
	h1: 40,
	h2: 28,
	h3: 20,
	h4: 16,
	h5: 14,
	h6: 10,
	subtitle1: 10,
	subtitle2: 14,
	body1: 8,
	body2: 10,
}

export const PDF_SMALL_FONT_SIZE = {
	fontSize: 16,
	h1: 32,
	h2: 24,
	h3: 16,
	h4: 14,
	h5: 12,
	h6: 8,
	subtitle1: 8,
	subtitle2: 12,
	body1: 6,
	body2: 8,
}

export const SPACING_FACTOR = 0.5

export const THEME_TYPE = {
	DARK: 'dark',
	LIGHT: 'light',
}
