import { getLuminance } from '@material-ui/core/styles'
import { THEME_TYPE } from '@v4/constants/pages'
import { LUMINANCE_BOUNDARY } from '@v4/constants/theme'
import { SCORE_FORMAT, SCORE_TYPE } from '@v4/constants/result'
import Denominator from '@v4/components/common/Denominator'
import React from 'react'

export const isDarkColor = color => getLuminance(color) < LUMINANCE_BOUNDARY

export const defineTheme = (bgColor, defaultType = THEME_TYPE.LIGHT) => {
	try {
		return isDarkColor(bgColor) ? THEME_TYPE.DARK : THEME_TYPE.LIGHT
	} catch (e) {
		return defaultType
	}
}

export function createArray(length = 0, fill) {
	const arr = []
	const fillFunc = typeof fill === 'function' ? fill : i => i

	for (let i = 0; i < length; i += 1) {
		arr.push(fillFunc(i))
	}
	return arr
}

export const parseValidationError = errors => {
	if (Array.isArray(errors) && errors.length) {
		return errors[0]
	}

	if (!!errors && typeof errors === 'object') {
		return Object.entries(errors).reduce((acc, [key, value]) => {
			const keyArr = key.split('.')
			keyArr.reduce((subAcc, subKey, index) => {
				if (typeof subAcc === 'string') {
					return subAcc
				}
				if (typeof subAcc[subKey] === 'undefined') {
					// eslint-disable-next-line no-param-reassign
					subAcc[subKey] = typeof keyArr[index + 1] === 'undefined' ? parseValidationError(value) : {}
				}
				return subAcc[subKey]
			}, acc)

			return acc
		}, {})
	}

	return errors
}

export const parseScorePercent = value => {
	return _.clamp(Math.round(value * 100), 0, 100)
}

/**
 * @typedef {Object} Tier
 * @property {number} id - tier ID
 * @property {string} name - custom tier name
 * @property {string} score_colour - tier hex colour
 * @property {number} score_from - start of tier range
 * @property {number}  score_to - end of tier range
 */

/**
 * @param {Array<Tier>} tiers
 * @param {number} value - range from 0 to 100
 * @param {number|null} tierId - tier id
 * @return {Tier}
 */
export function getTier(tiers, value = 0, tierId = null) {
	return (tiers || []).find(({ score_to: to, id }) => {
		return tierId ? tierId === id : parseScorePercent(value) <= to
	})
}

export function getFormattedScore(
	format,
	percent,
	score,
	{ withDenominator, denominatorClassName, totalPercent } = {},
) {
	if (typeof score === 'undefined' || score === null) {
		return null
	}
	const typedValue = {
		[SCORE_FORMAT.PERCENTAGE]: `${percent}%`,
		[SCORE_FORMAT.SCORE]: parseFloat(score),
		[SCORE_FORMAT.DENOMINATOR_10]: withDenominator ? (
			<Denominator className={denominatorClassName} percent={percent} />
		) : (
			`${Math.floor(percent / 10)}/10`
		),
		[SCORE_FORMAT.OUT_OF_10]: `${Math.round(percent) / 10}`,
		[SCORE_FORMAT.OUT_OF_10_ROUNDED]: `${Math.floor(percent / 10)}`,
		[SCORE_FORMAT.RELATIVE_PERCENT]: `${totalPercent ? Math.round((percent * 100) / totalPercent) : 0}%`,
	}

	return typedValue[format]
}

export function getOverallScore(scores) {
	return (scores || []).find(({ type }) => type === SCORE_TYPE.TOTAL) || {}
}
