/* eslint-disable class-methods-use-this */
import BaseModel, { Action, Thunk } from '@v4/utils/model/BaseModel'
import PageBuilderSelector from '@v4/utils/selectors/PageBuilderSelector'
import PageTemplateData from '@v4/utils/data/PageTemplateData'
import PageSectionCollection from '@v4/utils/collections/PageSectionCollection'
import { nanoid } from 'nanoid'
import { parseScorePercent } from '@v4/utils'
import BaseData from '@v4/utils/data/BaseData'
import PageMetaData from '@v4/utils/data/PageMetaData'
import PageData from '@v4/utils/data/PageData'
import previewModel from './Preview.model'

const VIEW = Object.freeze({
	MOBILE: 'MOBILE',
	LANDSCAPE: 'LANDSCAPE',
})

const SIDEBAR_MODE = Object.freeze({
	SECTIONS: 'SECTIONS',
	SETTINGS: 'SETTINGS',
	ADDITION: 'ADDITION',
	QUESTIONS: 'QUESTIONS',
	CATEGORIES: 'CATEGORIES',
	THEME: 'THEME',
})

const SCROLLING_TYPE = Object.freeze({
	PREVIEW: 'preview',
	SIDEBAR: 'sidebar',
})

const SETTINGS_TYPE = Object.freeze({
	GENERAL: 'general',
	CSS: 'css',
	SCRIPT: 'script',
	DEFAULT: 'default',
})

class PageBuilderModel extends BaseModel {
	/**
	 * @returns {Readonly<{LANDSCAPE: string, MOBILE: string}>}
	 */
	static get VIEW() {
		return VIEW
	}

	/**
	 * @returns {Readonly<{
	 * QUESTIONS: string,
	 * ADDITION: string,
	 * SECTIONS: string,
	 * THEME: string,
	 * SETTINGS: string,
	 * CATEGORIES: string
	 * }>}
	 */
	static get SIDEBAR_MODE() {
		return SIDEBAR_MODE
	}

	/**
	 * @returns {Readonly<{SIDEBAR: string, PREVIEW: string}>}
	 */
	static get SCROLLING_TYPE() {
		return SCROLLING_TYPE
	}

	/**
	 * @returns {Readonly<{CSS: string, SCRIPT: string, GENERAL: string, DEFAULT: string}>}
	 */
	static get SETTINGS_TYPE() {
		return SETTINGS_TYPE
	}

	get initialData() {
		const { score_tiers: scoreTiers, shortcuts, template: initialTemplate, page, result = {} } = window.initialData
		const scoreTierIds = _.map(scoreTiers, ({ id }) => id)
		const { sections: pageSections, theme_settings: pageThemeSettings, ...data } = page

		const template = { version: data.version, ...PageTemplateData.create(initialTemplate).data }

		const selector = new PageBuilderSelector({ data, template })

		const { categories, categoryTierIds } = _.reduce(
			result.scores,
			(acc, { category, ...score }) => {
				if (category) {
					acc.categories.push(category)
				}

				const categoryId = category ? category.id : 'total'

				const categoryScoreTier = _.find(scoreTiers, tier => {
					const scorePercent = parseScorePercent(score.score_percent)
					return scorePercent >= tier.score_from && scorePercent <= tier.score_to
				})

				acc.categoryTierIds[categoryId] = categoryScoreTier?.id
				return acc
			},
			{
				categories: [],
				categoryTierIds: {},
			},
		)

		const sections = PageSectionCollection.create(
			_.sortBy(pageSections, ['order']),
			section => {
				const meta = this.#pageSectionMetaFrom({
					categories,
					meta: section.meta,
					fieldGroups: selector.selectTemplateFieldGroups(section.key),
					scoreTierIds,
					categoryId: section.category_id,
					categoryTierIds,
				})

				return {
					audiences_ids: [],
					...section,
					meta,
				}
			},
			template,
		).data

		const preparedThemeSettings = PageData.isTypeResultPdf(data.type)
			? pageThemeSettings
			: this.#prepareThemeSettings(pageThemeSettings, selector.selectTemplateFieldGroups().fields)

		const themeSettings = this.#pageSectionMetaFrom({
			fieldGroups: selector.selectTemplateFieldGroups(),
			meta: preparedThemeSettings,
			scoreTierIds,
		})

		return {
			loading: false,
			updating: false,
			error: false,
			actionPending: null,
			pageId: null,
			data,
			sections,
			themeSettings,
			shortcuts,
			template,
			audiences: [],
			view: VIEW.LANDSCAPE,
			sidebarMode: SIDEBAR_MODE.SECTIONS,
			activeGroup: null,
			activeSectionId: null,
			isBuilderMode: true, // necessary for v-4 react app
			initial: {},
			minimize: {},
			gallery: null,
			focusedField: null,
			scoreTierIds: [],
			additionalIndex: null,
			preview: previewModel,
			activePageType: null,
			leadFormModalActive: false,
			signupLocationConfirmData: null,
		}
	}

	#activeTierIdFrom = (entities, lowestTierId) => {
		return _.some(entities, entity => {
			return !!entity.score_tier_id
		})
			? lowestTierId
			: null
	}

	#findMetaEntity = (entities, { metaKey, tierId = null, index = 0 }) => {
		return (
			_.find(
				entities,
				entity =>
					entity.meta_key === metaKey &&
					(entity.meta_key_index ?? 0) === index &&
					entity.score_tier_id === tierId,
			) || null
		)
	}

	#metaFieldsFrom = (entities, fields, scoreTierIds, index) => {
		return _.reduce(
			fields,
			(fieldsAcc, metaKey) => {
				fieldsAcc[metaKey] = _.reduce(
					scoreTierIds,
					(tierAcc, tierId) => {
						tierAcc[tierId] = this.#findMetaEntity(entities, { metaKey, tierId, index })
						return tierAcc
					},
					{
						default: this.#findMetaEntity(entities, { metaKey, index }),
					},
				)
				return fieldsAcc
			},
			{},
		)
	}

	#metaListItemFrom = (entities, { fields, legacy, scoreTierIds, lowestTierId, index }) => {
		const result = {
			activeTierId: this.#activeTierIdFrom(entities, lowestTierId),
			key: nanoid(),
			fields: this.#metaFieldsFrom(entities, fields, scoreTierIds, index),
		}

		if (typeof legacy !== 'undefined') {
			result.legacy = legacy
		}

		return result
	}

	#metaListWithCategoriesFrom = (entities, { categories, config, scoreTierIds, categoryTierIds }) => {
		const { fields, legacy } = config
		const filteredEntities = _.filter(entities, entity => {
			return !!entity.category_id && fields.includes(entity.meta_key)
		})

		return _.reduce(
			categories,
			(listAcc, { id }) => {
				const categoryEntities = _.filter(filteredEntities, { category_id: id })

				listAcc[id] = this.#metaListItemFrom(categoryEntities, {
					fields,
					legacy,
					scoreTierIds,
					lowestTierId: categoryTierIds[id],
				})

				return listAcc
			},
			{},
		)
	}

	#metaListWithoutCategoriesFrom = (entities, { config, group, scoreTierIds, lowestTierId = null }) => {
		const { fields, repeatable, repeatable_default: repeatableDefault, repeatable_min: repeatableMin } = config

		const filteredEntities = _.filter(entities, entity => {
			return (
				!entity.category_id &&
				fields.includes(entity.meta_key) &&
				entity.repeatable_group === (repeatable ? group : null)
			)
		})

		const length = repeatable
			? _.reduce(
					entities,
					(accLength, { repeatable_group: repeatableGroup, meta_key_index: index }) => {
						return repeatableGroup === group ? Math.max(accLength, index + 1) : accLength
					},
					null,
			  ) ??
			  repeatableDefault ??
			  repeatableMin
			: 1

		return _.reduce(
			Array.from({
				length,
			}),
			(listAcc, value, index) => {
				const item = this.#metaListItemFrom(filteredEntities, { fields, index, scoreTierIds, lowestTierId })

				listAcc.push(item)

				return listAcc
			},
			[],
		)
	}

	#metaGroupDataFrom = (state, sectionId, group) => {
		return new BaseData(new PageBuilderSelector(state).selectMetaGroup(sectionId, { group }))
	}

	#pageSectionMetaFrom = ({
		fieldGroups: { groups },
		meta,
		categories,
		categoryId = null,
		categoryTierIds = {},
		scoreTierIds,
	}) => {
		return _.reduce(
			groups,
			(acc, config, group) => {
				const { type, repeatable } = config

				const list = PageTemplateData.isGroupTypeCategory(type)
					? this.#metaListWithCategoriesFrom(meta, {
							categories,
							config,
							scoreTierIds,
							categoryTierIds,
					  })
					: this.#metaListWithoutCategoriesFrom(meta, {
							group,
							config,
							lowestTierId: categoryTierIds[categoryId ?? 'total'],
							scoreTierIds,
					  })
				acc[group] = {
					activeCategoryId: null,
					activeItemKey: null,
					type,
					repeatable,
					list,
				}

				return acc
			},
			{},
		)
	}

	#prepareThemeSettings = (themeSettings, fieldConfigs) => {
		if (!fieldConfigs) {
			return themeSettings
		}

		const h1Key = 'typography.h1'
		const bodyKey = 'typography.body'

		const h1Config = fieldConfigs[h1Key]
		const bodyConfig = fieldConfigs[bodyKey]

		if (!h1Config && !bodyConfig) {
			return themeSettings
		}

		const entities = _.keyBy(themeSettings, 'meta_key')

		const h1Meta = entities[h1Key]
		const bodyMeta = entities[bodyKey]
		const headingFontMeta = entities.headingTextFont
		const headingFontSizeMeta = entities.headingFontSize
		const bodyFontMeta = entities.bodyTextFont
		const bodyFontSizeMeta = entities.fontSize

		if (h1Config && !h1Meta && (headingFontMeta || headingFontSizeMeta)) {
			const fontFamily = headingFontMeta?.meta_value
			const fontSize = headingFontSizeMeta?.meta_value
				? Math.round(+headingFontSizeMeta.meta_value / 0.2963)
				: undefined

			try {
				const metaData = new PageMetaData(null, h1Config).fill({
					meta_key: h1Key,
					meta_value: JSON.stringify({ fontFamily, fontSize }),
				})

				entities[h1Key] = metaData.data
				// eslint-disable-next-line no-empty
			} catch (error) {}
		}

		if (bodyConfig && !bodyMeta && (bodyFontMeta || bodyFontSizeMeta)) {
			const fontFamily = bodyFontMeta?.meta_value
			const fontSize = bodyFontSizeMeta?.meta_value ? Math.round(+bodyFontSizeMeta.meta_value) : undefined

			try {
				const metaData = new PageMetaData(null, bodyConfig).fill({
					meta_key: bodyKey,
					meta_value: JSON.stringify({ fontFamily, fontSize }),
				})

				entities[bodyKey] = metaData.data
				// eslint-disable-next-line no-empty
			} catch (error) {}
		}

		delete entities.headingTextFont
		delete entities.headingFontSize
		delete entities.bodyTextFont
		delete entities.fontSize

		return _.values(entities)
	}

	@Action
	setLeadFormModalActive() {}

	@Action
	setSignupLocationConfirmData() {}

	@Action
	setInitialData() {}

	@Action
	setSidebarMode(state, payload) {
		_.assignIn(state, {
			sidebarMode: payload,
			activeSectionId: null,
			gallery: null,
			focusedField: null,
			additionalIndex: null,
		})
	}

	@Action
	setView() {}

	@Action
	setActiveGroup() {}

	@Action
	updateGallery() {}

	@Action
	setFocusedField(state, payload) {
		state.focusedField = payload
	}

	@Action
	setActiveSectionId(state, { id }) {
		state.activeSectionId = id
		state.sidebarMode = this.constructor.SIDEBAR_MODE.SECTIONS
	}

	@Action
	setActiveCategoryId(state, payload) {
		state.activeCategoryId = payload
	}

	@Action
	setActiveItemKey(state, { sectionId, group, itemKey }) {
		this.#metaGroupDataFrom(state, sectionId, group)
			.fill({ activeItemKey: itemKey })
			.save()
	}

	@Action
	toggleMinimize() {}

	@Action
	setGallery() {}

	@Action
	setAdditionalIndex() {}

	@Action
	updateData() {}

	@Action
	sortSections() {}

	@Action
	updateSection() {}

	@Action
	addSectionAction() {}

	@Thunk
	addSection() {}

	@Action
	deleteSection() {}

	@Action
	setMeta() {}

	@Action
	sortRepeatableMeta() {}

	@Action
	addRepeatableGroup() {}

	@Action
	deleteRepeatableGroup() {}

	@Action
	setDynamicContent() {}

	@Action
	copyDynamicContent() {}

	@Thunk
	fetch() {}

	@Thunk
	update() {}

	@Thunk
	fetchAudiences() {}

	@Action
	setActivePageType() {}
}

export default PageBuilderModel
